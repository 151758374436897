import * as React from "react"
import Img, { GatsbyImageFixedProps } from "gatsby-image"

const ProductItem = ({ product }: { product: ProductItemProps }) => (
  <div className="br3 ba dark-gray b--black-10 mv4 w-100 w-50-m w-25-l mw5 center">
    <a
      className="link flex flex-column content-center near-black hover-light-blue"
      href={`/products${product.fields.slug}`}
    >
      {product.frontmatter.cover && (
        <figure className="center">
          <Img
            className="center br3 br--top"
            fixed={product.frontmatter.cover.childImageSharp.fixed}
            alt={`Cover for "${product.frontmatter.title}"`}
          />
          <br />
          <caption className="dn">
            Cover for <em className="i">{product.frontmatter.title}</em>
          </caption>
        </figure>
      )}
      <div className="pa2 ph3-ns pb3-ns">
        <hgroup className="flex flex-column justify-around w-100 mt1 sans-serif">
          <h1 className="f6 f5-ns tc mv0">{product.frontmatter.title}</h1>
        </hgroup>
        <p className="f6 lh-copy measure near-black">{product.excerpt}</p>
      </div>
    </a>
  </div>
)

export interface ProductItemProps {
  excerpt: string
  frontmatter: {
    title: string
    cover?: { childImageSharp: GatsbyImageFixedProps }
  }
  fields: { slug: string }
}

export default ProductItem
